import React, { ReactNode, ReactEventHandler } from "react";
import spinner from "/assets/spinner.svg?url";
import doneImg from "/assets/spc_mobile_success_hand_d_l.svg?url";
import errorImg from "/assets/ic_error_modal.svg?url";
import { cn } from "@/lib/utils";

// export type OPEN = "open";
// export type LOADING = "loading";
// export type DONE = "done";
// export type ERROR = "error";
// export type CLOSE = "close";
// export type ModalState = OPEN | LOADING | DONE | ERROR | CLOSE;
export enum ModalState {
    OPEN = "open",
    LOADING = "loading",
    DONE = "done",
    ERROR = "error",
    CLOSE = "close",
}

interface BaseModalProps {
    onClose: ReactEventHandler;
    showAlertIcon?: boolean;
    children: ReactNode;
    showIconClose?:boolean;
}

// generic in case doesn't use states for modal
interface ModalProps extends BaseModalProps {
    modalState: ModalState;
    typeModal: 'downloadExcel' | 'aprobeReport' | 'editReport' | 'aprobeMasiveReport' | 'editMasiveReport' | 'generic';
    modalWidth?: string;
    className?:string
}

interface ModalLabels {
    loading: {
        title: string;
        subtitle: string;
    };
    success: {
        title: string;
        subtitle: string;
    };
}

interface ModalValues {
    [key: string]: ModalLabels;
}

const valuesModal: ModalValues = {
    downloadExcel: {
        loading: {
            title: 'Generando',
            subtitle: 'Este proceso puede tardar hasta unos minutos.'
        },
        success: {
            title: 'Descarga completada',
            subtitle: 'Puedes configurar este excel como tú prefieras'
        }
    },
    aprobeReport: {
        loading: {
            title: 'Procesando',
            subtitle: 'Este proceso puede tardar hasta unos minutos.'
        },
        success: {
            title: '¡Listo!',
            subtitle: 'Tu factura está rechazada. Ya no se sumará en tus comprobantes deducibles.'
        }
    },
    aprobeMasiveReport: {
        loading: {
            title: 'Procesando',
            subtitle: 'Este proceso puede tardar hasta unos minutos.'
        },
        success: {
            title: '¡Listo!',
            subtitle: 'Las facturas seleccionadas fueron rechazadas. Ya no se sumará en tus comprobantes deducibles.'
        }
    },
    editReport: {
        loading: {
            title: 'Procesando',
            subtitle: 'Este proceso puede tardar hasta unos minutos.'
        },
        success: {
            title: '¡Listo!',
            subtitle: 'Tu factura está validada. Podrás visualizarla en tus facturas deducibles y en tu reporte en Excel.'
        }
    },
    editMasiveReport: {
        loading: {
            title: 'Procesando',
            subtitle: 'Este proceso puede tardar hasta unos minutos.'
        },
        success: {
            title: '¡Listo!',
            subtitle: 'Las facturas seleccionadas fueron validadas. Podrás visualizarla en tus facturas deducibles y en tu reporte en Excel.'
        }
    }
}

const ModalContent: React.FC<BaseModalProps> = ({ showAlertIcon, onClose, children, showIconClose }) => {
    return (
        <>
            {
                showIconClose &&  
                <button onClick={onClose} className="absolute top-4 right-4 text-gray-700 hover:text-gray-900">
                <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14">
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
                </button>
            }
            <div className="flex">
                {showAlertIcon && (
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg
                            className="h-6 w-6 text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                            />
                        </svg>
                    </div>
                )}

                {children}
            </div>
        </>
    );
};

const ModalContentLoading: React.FC<{labels: ModalLabels}> = ({labels}) => {
    return (
        <div className="block">
            <div className="flex flex-col w-full items-center justify-center rounded-full bg-transparent">
                <div className="p-4">
                    <img className="animate-spin h-8 w-8 text-blue-600" src={spinner} alt="spinner" />
                </div>
                <div>
                    <p className="text-lg text-center text-gray-700">{labels.loading.title}</p>
                    <p className="text-sm text-center">{labels.loading.subtitle}</p>
                </div>
            </div>
        </div>
    );
};

const ModalDone: React.FC<{ onClose: ReactEventHandler, labels: ModalLabels, typeModal: 'downloadExcel' | 'aprobeReport' | 'editReport' | 'aprobeMasiveReport' | 'editMasiveReport' | 'generic' }> = ({ onClose, labels, typeModal }) => {
    const setValueTagIdForHotjar = () => {
        return typeModal === 'editReport' ? 'id_validar_reporte' : 'id_rechazar_roporte';
    }

    return (
        <>
            <button onClick={onClose} className="absolute top-4 right-4 text-gray-700 hover:text-gray-900">
                <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14">
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
            </button>
            <div className="block">
                <div className="flex flex-col w-full items-center justify-center rounded-full bg-transparent">
                    <div className="p-4">
                        <img src={doneImg} alt="Done" />
                    </div>
                    <div>
                        <p className="text-lg text-center text-gray-700">{labels.success.title}</p>
                        <p className="text-sm text-center">{labels.success.subtitle}</p>
                        {
                            (typeModal === 'editReport' || typeModal === 'aprobeReport') && <div id={setValueTagIdForHotjar()}></div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

const ModalError: React.FC<{ onClose: ReactEventHandler }> = ({ onClose }) => {
    return (
        <>
            <button onClick={onClose} className="absolute top-4 right-4 text-gray-700 hover:text-gray-900">
                <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14">
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
            </button>
            <div className="block">
                <div className="flex flex-col w-full items-center justify-center rounded-full bg-transparent">
                    <div className="p-4">
                        <img src={errorImg} alt="Done" />
                    </div>
                    <div>
                        <p className="text-lg text-center text-gray-700">Ocurrió un error</p>
                        <p className="text-sm text-center">Por favor, inténtalo de nuevo en un minuto.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

const ModalWrapper: React.FC<{ children: ReactNode, modalWidth?: string, className?: string  }> = ({ children, modalWidth, className = "" }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className={cn(`relative z-50 bg-white p-6 rounded-md shadow-md min-w-64 ${modalWidth ? modalWidth : ' w-5/6 md:w-3/6'}`,className)}>{children}</div>
        </div>
    );
};

const Modal: React.FC<ModalProps> = ({ modalState, onClose, children, typeModal, modalWidth = "w-2/4", showAlertIcon: showAlert = true, showIconClose = true, className= "" }) => {
    if (modalState === ModalState.CLOSE) {
        return null;
    }

    if (modalState === ModalState.LOADING) {
        return (
            <ModalWrapper modalWidth={modalWidth}>
                <ModalContentLoading labels={valuesModal[typeModal]}/>
            </ModalWrapper>
        );
    }

    if (modalState === ModalState.DONE) {
        return (
            <ModalWrapper modalWidth={modalWidth}>
                <ModalDone onClose={onClose} labels={valuesModal[typeModal]} typeModal={typeModal}/>
            </ModalWrapper>
        );
    }

    if (modalState === ModalState.ERROR) {
        return (
            <ModalWrapper>
                <ModalError onClose={onClose} />
            </ModalWrapper>
        );
    }

    return (
        <ModalWrapper modalWidth={modalWidth} className={className}>
            {modalState === ModalState.OPEN && (
                <ModalContent showAlertIcon={showAlert} showIconClose={showIconClose} onClose={onClose}>
                    {children}
                </ModalContent>
            )}
        </ModalWrapper>
    );
};

export default Modal;
