import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import useDownloadStatusMessage from "../hooks/useDownloadStatusMessages";
import { StatusValues } from "../models/status-download";
import Modal, { ModalState } from "@/shared/Modal";
import { useApplicationStore } from "@/store/ApplicationStore";
import useLoadImage from "../hooks/useLoadImage";

interface DownloadAlertModalProps {
  status: StatusValues;
  open: boolean;
  onClose(): void;
}

interface PreviewFileModalProps {
  fileUrl: string;
  modalState: ModalState;
	closeModalCallback: () => void;
}

export function DownloadAlertModal({ status, open, onClose }: DownloadAlertModalProps) {
  const { title, description, icon } = useDownloadStatusMessage(status);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="text-center items-center gap-4">
          <img src={icon} className="w-16 h-16" />
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export function PreviewFileModal({ fileUrl, modalState, closeModalCallback }: PreviewFileModalProps) {
	const { addLoadingTag, removeLoadingTag } = useApplicationStore();
  const { isImageLoaded } = useLoadImage(fileUrl, modalState, addLoadingTag, removeLoadingTag);

  return (
    isImageLoaded && (
      <Modal
        modalState={modalState}
        typeModal="generic"
        modalWidth="w-3/6"
        showAlertIcon={false}
        onClose={closeModalCallback}
      >
        <img src={fileUrl} alt="imagen de comprobante" className="p-3"/>
      </Modal>
    )
  );
}
