import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { useApplicationStore } from "./ApplicationStore";
import { useDashboardStore } from "./DashboardStore";
import axios, { AxiosError } from "axios";
import { eventGa4Service } from "../ga4.service";
import { CONTAPRO_ANALYTICS } from "../shared/analytics";

const LOCAL_API_BASE_URL = import.meta.env.VITE_API_BASE_URL || "http://localhost:3000";

const EMPTY_USER: User = {
    uuid: null,
    name: "",
    last_name: "",
    document_number: "",
    email: "",
    phone_number: "",
    quantity_business: 0,
    accountant_profile: "",
    subscription: null,
    password: "",
    confirm_password: "",
    terms: false,
    privacy: false,
};

export enum LoginStatus {
    SUCCESS = "SUCCESS",
    NOT_LOGGED = "NOT_LOGGED",
    ERROR = "ERROR",
}

export interface AuthState {
    user: User;
    loginCredentials: LoginCredentials;
    accessToken: string | null;
    recoveryToken: string | null;
    accessTokenExpired: boolean;
    errorLogin: boolean;
    loginStatus: LoginStatus;
    errorMessages: ErrorMessage[];
    clearErrorsMessages: () => void;
    setLoginRequest: (user: LoginCredentials) => void;
    setAccessTokenExpired: (isExpired: boolean) => void;
    setUserToken: (token: string | null) => void;
    setNewUser: (user: User) => void;
    login: () => Promise<void>;
    createUser: () => Promise<void>;
    sendEmailToRecoverPassword: (email: string) => Promise<void>;
    validateRecoveryToken: (token: string) => Promise<void>;
    updatePsw: (password: string) => Promise<void>;
    getMe: () => Promise<void>;
    logout: () => void;
}

export const useAuthStore = create<AuthState>()(
    persist(
        // type: ignore
        // @ts-ignore
        (set, get) => ({
            user: {
                uuid: null,
                name: "",
                last_name: "",
                document_number: "",
                email: "",
                phone_number: "",
                quantity_business: 0,
                accountant_profile: "",
                password: "",
                subscription: null,
                confirm_password: "",
                terms: false,
                privacy: false,
            },
            loginCredentials: {
                email: "",
                password: "",
            },
            accessToken: null,
            recoveryToken: null,
            accessTokenExpired: false,
            errorLogin: false,
            loginStatus: LoginStatus.NOT_LOGGED,
            errorMessages: [],
            clearErrorsMessages() {
                set({errorMessages: []})
            },
            setLoginRequest(loginRequest: LoginCredentials) {
                set({ errorMessages: [] });
                set({ loginCredentials: loginRequest });
            },
            setAccessTokenExpired(isExpired: boolean) {
                set({ accessTokenExpired: isExpired })
            },
            setUserToken(userToken: string | null) {
                set({ accessToken: userToken });
            },
            setNewUser(user: User) {
                set({ user: user });
            },
            async login() {
                useApplicationStore.getState().addLoadingTag("LOADING_LOGIN");
                const user = get().loginCredentials;

                set({ errorMessages: [] });

                try {
                    const response = await axios.post(
                        `${LOCAL_API_BASE_URL}/app/internal/users/sign-in`,
                        {
                            email: user.email,
                            password: user.password,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    );

         
                    if (response.status === 200) {
                        set({ accessToken: response.data.access_token });
                        set({ loginStatus: LoginStatus.SUCCESS });
                        return Promise.resolve();
                    } else {
                        return Promise.reject(new Error(`Error: ${response.statusText}`));
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        const axiosError = error as AxiosError<any>;
                        const errors = axiosError.response?.data.errors as any[];
                        const errorMessages: ErrorMessage[] = errors.map((error: any) => error.message);
                        if (errors.some(error => error.code === "USER_PASSWORD_INCORRECT")) {
                            eventGa4Service({
                                action: CONTAPRO_ANALYTICS.AUTH.LOGIN_PAGE.LOGIN_ERROR.ACTION
                            });
                        }
                        set({ loginStatus: LoginStatus.ERROR });
                        set({ errorMessages: [...get().errorMessages, ...errorMessages] });
                    } else {
                        set({ loginStatus: LoginStatus.ERROR });
                        set({ errorMessages: [...get().errorMessages, String(error)] });
                    }
                    
                } finally {
                    set({ loginCredentials: { email: "", password: "" } });
                    await useDashboardStore.getState().resetFilters();
                    useApplicationStore.getState().removeLoadingTag("LOADING_LOGIN");
                }

                return Promise.resolve();
            },
            async createUser() {
                useApplicationStore.getState().addLoadingTag("LOADING_CREATE_USER");
                const {
                    name,
                    last_name,
                    document_number,
                    email,
                    phone_number,
                    quantity_business,
                    accountant_profile,
                    password,
                } = get().user;

                set({ errorMessages: [] });

                try {
                    const response = await axios.post(
                        `${LOCAL_API_BASE_URL}/app/internal/users`,
                        {
                            name,
                            last_name,
                            document_number,
                            email,
                            phone_number,
                            quantity_business,
                            accountant_profile,
                            password,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    );

                    if (response.status === 200 && response.data && response.data.access_token) {
                        set({ accessToken: response.data.access_token });
                        return Promise.resolve();
                    } else {
                        return Promise.reject(new Error(`Error: ${response.statusText}`));
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        const axiosError = error as AxiosError<any>;
                        const errors = axiosError.response?.data.errors as any[];
                        const errorMessages: ErrorMessage[] = errors.map((error: any) => error.message);

                        set({ errorMessages: [...get().errorMessages, ...errorMessages] });

                        // Throwing 404 error
                        throw new Response(error as any, { status: error.response?.status });
                    } else {
                        set({ errorMessages: [...get().errorMessages, String(error)] });

                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    set({
                        user: EMPTY_USER,
                    });
                    await useDashboardStore.getState().resetFilters();
                    useApplicationStore.getState().removeLoadingTag("LOADING_CREATE_USER");
                }

                return Promise.resolve();
            },
            async sendEmailToRecoverPassword(email: string) {
                useApplicationStore.getState().addLoadingTag("LOADING_SEND_EMAIL_TO_RECOVER_PASSWORD");
            
                set({ errorMessages: [] });
            
                try {
                    const response = await axios.post(
                        `${LOCAL_API_BASE_URL}/app/internal/users/recover-password`,
                        {
                            email,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
            
                    if (response.status === 200) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject(new Error(`Error: ${response.statusText}`));
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        const axiosError = error as AxiosError<any>;
                        const errors = axiosError.response?.data.errors as any[];
                        const errorMessages: ErrorMessage[] = errors.map((error: any) => error.message);
            
                        set({ errorMessages: [...get().errorMessages, ...errorMessages] });
                    } else {
                        set({ errorMessages: [...get().errorMessages, String(error)] });
                    }
            
                    return Promise.reject(error);
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_SEND_EMAIL_TO_RECOVER_PASSWORD");
                }
            },
            async validateRecoveryToken(token: string) {
                useApplicationStore.getState().addLoadingTag("LOADING_VALIDATE_RECOVERY_TOKEN");
            
                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/users/recover-password?recovery_token=${token}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
            
                    if (response.status === 204) {
                        set({recoveryToken: token});
                        return Promise.resolve();
                    } else {
                        return Promise.reject(new Error(`Error: ${response.statusText}`));
                    }
                } catch (error) {
                    console.log(error);
                    return Promise.reject(error);
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_VALIDATE_RECOVERY_TOKEN");
                }
            },
            async updatePsw(password: string) {
                useApplicationStore.getState().addLoadingTag("LOADING_UPDATE_PSW");
                const token = get().recoveryToken;
            
                set({ errorMessages: [] });
            
                try {
                    const response = await axios.put(
                        `${LOCAL_API_BASE_URL}/app/internal/users/password`,
                        {
                            token,
                            password
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
            
                    if (response.status === 204) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject(new Error(`Error: ${response.statusText}`));
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        const axiosError = error as AxiosError<any>;
                        const errors = axiosError.response?.data.errors as any[];
                        const errorMessages: ErrorMessage[] = errors?.map((error: any) => error.message);
            
                        set({ errorMessages: [...get().errorMessages, ...errorMessages] });
                    } else {
                        set({ errorMessages: [...get().errorMessages, String(error)] });
                    }
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_UPDATE_PSW");
                }
            },
            async getMe() {
                useApplicationStore.getState().addLoadingTag("LOADING_GET_ME");
                try {
                    const response = await axios.get(`${LOCAL_API_BASE_URL}/app/internal/me`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${get().accessToken}`,
                        },
                    });

                    set({ accessTokenExpired: false });
                    set({ user: response.data });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            set({ accessToken: null });
                            set({ user: EMPTY_USER });
                        }
                    } else {
                        console.error("Error getting Me...");
                    }
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_GET_ME");
                }

                return Promise.resolve();
            },
            async logout() {
                await useDashboardStore.getState().resetFilters();
                set({ loginCredentials: { email: "", password: "" } });
                set({ accessToken: null });
                set({ user: EMPTY_USER });
                set({ accessTokenExpired: false });
                sessionStorage.clear();
            },
        }),
        {
            name: "auth-storage",
            storage: createJSONStorage(() => sessionStorage),
            version: 2,
            partialize: (state) => ({ accessToken: state.accessToken, user: state.user }),
        },
    ),
);
